// Override default variables before the import

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
$font-family-dm-sans: 'DM Sans', sans-serif; 
$font-family-base: $font-family-dm-sans;
$font-weight-bold: 500;
$font-weight-bolder: 700;

// $body-bg: #FBF7FF;
$body-color: #374151;
$text-muted: #9CA3AF;


$navbar-light-color: #9CA3AF;
$navbar-padding-y:15px;
$navbar-light-active-color: #374151;
$navbar-nav-link-padding-x: 2rem;

$primary: #3524f7;
$secondary: #FDA4AF;
$border-color: #F3F4F6;

// $btn-box-shadow: inset 0 1px 0 rgba($primary, .15), 0 1px 1px rgba($secondary, .075);
@mixin gradient-button($start-color: rgba($primary, .3), $end-color: $secondary, $start-percent: 0%, $end-percent: 166.97%) {
    background-color: $start-color;
    background: linear-gradient(360deg, $start-color $start-percent, $end-color $end-percent);
}

@mixin gradient-button-dark($start-color: rgba($primary, .6), $end-color: $secondary, $start-percent: 0%, $end-percent: 166.97%) {
    background-color: $start-color;
    background: linear-gradient(360deg, $start-color $start-percent, $end-color $end-percent);
}


$btn-border-radius:          10px;
$btn-border-radius-sm:        10px;
$btn-border-radius-lg:        5px;
// $btn-font-weight: $font-weight-bold;

$input-group-addon-bg: #FAFAFA;
$alert-border-radius: 12px;

.btn-primary {
    @include gradient-button-dark();
    background-color: transparent !important;
    border-width: 0px !important;
}

.btn-lg {
    font-weight: $font-weight-bold !important;
    line-height: 2rem !important;
    font-size: 1rem !important;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
    font-weight: $font-weight-bold;
}

.nav-link.active {
    background-color: #FAFAFA;
    border-radius: 15px;
    border: 1px solid #E5E7EB;
}

$card-border-width:                 0px;
$card-border-radius:               28px;
.card-body {
    // box-shadow: 0px 4px 35px rgba(82, 93, 107, 0.03);
    padding: 36px 42px !important;
}

.token-list .token-list-item {
    background: #FAFAFA;
    border: 1px solid #E5E7EB;
    border-radius: 12px;
    padding: 18px 0px;
    margin-top: 15px;
}
.token-list-item .title {
    // font-weight: 500;
    font-size: 0.8rem;
    line-height: 0.9rem;
    color: #4B5563;
    margin-bottom: 2px;
}

.token-list-item .value img {
    height: 20px;
    margin-right: 7px;
}
.token-list-item .value {
    font-weight: bolder;
    font-size: 0.9rem;
    line-height: 23px;
    color: #6B6B6B;
}

.token-list-item button {
    font-size: 0.8rem;
}

.token-list-item button.action {
   max-width: 80px;
}

$modal-content-border-radius: 28px;
$list-group-border-radius:  15px;

.modal-header-token {
    position: absolute;
    left: 44%;
    top: -30px;
}

.modal-header-token .icon {
    background: white;
    padding: 8px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.modal-header-token img {
   height: 100%;
   width: 100%;
}

.text-light-1 {
   color: #919DAE;
}

.text-light-2 {
    color: #6B6B6B;
}

.text-balances {
    color: #4B5563;
    font-weight: bolder;
}

.slider-balance {
    background: #FAFAFA;
    border: 1px solid #E5E7EB;
    border-radius: 12px;
    padding: 15px 25px;
    line-height: 23px;
    font-weight: $font-weight-bold;
    color: #6B6B6B;
}

.slider-balance img {
    height: 15px;
}

.css-yvszuv-Slider {
    width: 100% !important;
}

.btn-close {
    background: url(images/close.svg) center/1em auto no-repeat !important;
}

.input-group .form-control, .input-group .input-group-text {
    border-radius: 12px;
}

.rounded-12 {
    border-radius: 12px;
}

.bg-beige {
    background: #FAFAFA;
    // border: 1px solid #E5E7EB;
    color: #6B6B6B;
}

.input-range__track {
   background:  #EEEBEB;
   border-radius: 4px;
   height: 0.5rem;
}

.input-range__track--active {
    background: #f7dee1;
}

.input-range__slider {
    background-color: transparent;
    background: transparent;
    background-image: url(./images/thumb.svg);
    width: 25px;
    height: 32px;
    border-radius: 0px;
    box-shadow: none;
    border: none;
    margin-top: -1.1rem;
}

.input-range__label {
    font-weight: light;
    color: rgba(0, 0, 0, 0.7);
}

.input-range__label--value {
    top: -2.5rem;
    margin-left: 5px;
    font-weight: bold;
    color: ---bs-dark-rgb;
}

.section-bg {
    position: absolute;
    width: 70%;
    height: 70vh;
    left: 15%;
    top: 80px;
    bottom: 40px;
    background: rgba(154, 36, 247, 0.06);
    filter: blur(200px);
    z-index: -1;
}

.cursor-pointer {
    cursor: pointer;
}

.input-range__label--value .input-range__label-container {
    padding: 3px;
    border-radius: 5px;
    background: #FAFAFA;
    border: 1px solid #E5E7EB;
}

.user-address {
    // @include gradient-button();
    font-size: 14px;
    font-weight: $font-weight-bold;
    // background-color: $secondary;
    padding: 7px 10px;
    border-radius: 10px;
    // color: #fff;
    border: 1px solid #D6D3FD;
}

.dapp-container {
    min-height: 100vh;
    background-color: #fbfbfb;
}

.modal-tab {
    border-bottom: 1px solid #F3F4F6;
}

.modal-tab-item {
    cursor: pointer;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500 !important;
    color: #919DAE;
}

.modal-tab-item.active {
    color: $primary;
    border-bottom: 4px solid $primary;
}

transaction-hash {
    color: $primary;
    color: $body-color;
}

.bg-black-modal .transaction-hash {
    color: white;
}
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
.input-group .input-group-text {
    // @include gradient-button();
    background-color: $secondary;
}


.wallet-balances { 
    background-color: white;
    padding: 8px 15px;
    display: inline-block;
    border-radius: 16px;
}

.wallet-balances span {
    font-size: 14px;
    font-weight: bold;
}

// dark theme overrides

.bg-black-modal {
    background-color: #292F41;
    // background-color: $dark;
}

.bg-black tr, .bg-black td  {
    background: transparent !important;
    background-color: transparent !important;
    color: #a0a0a0 !important;
}

.bg-black , .bg-black-modal, .bg-black .card-body {
    color: white !important;
}

.bg-black .card, .bg-black-modal .card, .bg-black .wallet-balances {
    background-color: $dark;
}

.bg-black .text-balances , .bg-black-modal .text-balances  {
    color: #ffffff;
}

.bg-black .token-list-item  , .bg-black-modal .token-list-item  { 
    background-color: $black;
    border: none;
}

.bg-black .token-list-item .value, .bg-black-modal .token-list-item .value {
    color: #afafaf   
}

.bg-black .token-list-item .title, .bg-black-modal .token-list-item .title {
    color: #a3adbb;
}

.bg-black .text-light-2, .bg-black-modal .text-light-2 {
    color: #a0a0a0;
}

.bg-black .text-dark, .bg-black-modal .text-dark {
    color: #ffffff !important;
}

.bg-black .nav-link.active {
    color: #ffffff !important;
    background-color: #212529;
    border-radius: 15px;
    border: none;
}
.bg-black .nav-link:hover {
    color: #ccc !important;
}

.bg-black .btn-primary, .bg-black-modal .btn-primary {
    @include gradient-button-dark();
}

.bg-black a, .bg-black-modal a {
    color: $blue-400;
}

.bg-black a.btn-primary, .bg-black-modal a.btn-primary {
    color: #ffffff;
}

.bg-black .text-light-1, .bg-black-modal .text-light-1 {
    color: #cbcfd4;
}

.bg-black-modal .modal-tab {
    border-bottom-color: $dark;
}

.bg-black-modal .modal-tab-item.active {
    color: $secondary;
    border-bottom-color: $secondary;
}

.bg-black-modal .modal-header-token .icon, .bg-black-modal .modal-header-image .image {
    background-color: #2d3843;
}

.bg-black .bg-beige, .bg-black-modal .bg-beige {
    background-color: #383E51;
}

.bg-black .input-group-text, .bg-black-modal .input-group-text {
    border: none;
}

.bg-black .input-range__label--min, .bg-black-modal .input-range__label--min {
    color: #ffffff;
}

.bg-black .input-range__label--max, .bg-black-modal .input-range__label--max {
    color: #ffffff;
}

.bg-black .slider-balance, .bg-black-modal .slider-balance {
    background-color: #383E51;
    border: none;
}

.bg-black-modal .input-range__track  {
    background: $black;
}

.bg-black-modal .input-range__track--active {
    background: $dark;
}

.bg-black-modal .breakup-value {
    color: #e5e5e5;
}

.bg-black-modal .input-group .input-group-text {
    // @include gradient-button();
    background-color: #292F41;
}

.modal-header-image {
    position: absolute;
    left: 40%;
    top: -50px;
}

.modal-header-image .image {
    background: white;
    padding: 16px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.modal-header-image img {
   height: 100%;
   width: 100%;
}

.semi-black-backdrop {
    background: rgba(0,0,0,0.7) !important;
}

.semi-white-backdrop {
    background: rgba(255, 255, 255, 0.7) !important;
}
.light-connect {
    background: #FAFAFA;
    border: 1px solid #E5E7EB;
}

.tx-table::-webkit-scrollbar {
    width: 0.5em;
  }
   
.tx-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.tx-table::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 15px;
    cursor: pointer;
}

.tx-table> table> tbody> tr> td {
    border-color: #3F3F3F !important;
}

.rangeslider {
    // background-color: #73c09c !important;
    background-color: $success !important;
    height: 5px !important;
}

.rangeslider__fill {
    // background-color: #e9828c !important;
    background-color: $danger !important;
}

.rangeslider-horizontal .rangeslider__handle  {
    width: 5px !important;
    height: 25px !important;
    border-radius: 0px;
    // background-color: $warning;
    // border-color: $warning;
}

.rangeslider-horizontal .rangeslider__handle:after {
    width: 0px !important;
    height: 0px !important;
    top: -3px !important;
    border-radius: 0px;
}

.rangeslider__labels> li:first-child {
    top: -32px !important;
}

.gas-col-container {
    border: 1px solid #212529;
    border-radius: 15px;
}

.gas-col {
    background-color: #202533;
    // padding: 5px;
    // border-radius: 5px;
    // border-color: #212529;
    // border-width: 0px;
    // border-bottom-width: 1px;
    // border-style: solid;
    // cursor: pointer;
    // margin-bottom: 0px;
}

.gas-col.active {
    background-color: #000000;
    border-color: #000000;
    // border-width: 2px;
}

.swap-icons {
    position: relative;
    margin: auto;
    width: 70px;
}
.swap-icons > img {
    top: -50px;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    left: 0px;
    background-color: #424243;
    box-shadow: 0 0 10px #040404
}

.swap-icons img:first-child {
    position: absolute;
    z-index: 2;
}

.swap-icons img:last-child {
    position: absolute;
    left: 30px;
    z-index: 1;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid $secondary;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.dex-quotes>div {
    border-bottom: 1px dashed #aaa;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.dex-quotes>div:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}


.token-list-item.borrowing {
    margin-bottom: 30px;
}

.token-list-item.borrowing:last-child {
    margin-bottom: 0px;
}

.swap-widget div.close {
    width: 100% !important;
}

.terms-lines::-webkit-scrollbar {
    -webkit-appearance: none;
}

.terms-lines::-webkit-scrollbar:vertical {
    width: 0.5em;
}
.terms-lines::-webkit-scrollbar {
    width: 0.5em;
  }
   
.terms-lines::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.terms-lines::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    cursor: pointer;
}

.web3modal-modal-lightbox {
    z-index: 1060 !important;
}


@media only screen and (max-width: 576px) {
    .token-list-item>div, .nft-list>div{
        margin-bottom: 1rem;
    }
}

.text-muted {
    color: #9CA3AF !important ;
}

stargate-widget > div {
    background-color: transparent !important;
}

.bg-black .dropdown-menu {
    background-color: #212529;
}

.dropdown-item {
    padding: 12px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}
.dropdown-item:last-child {
    border-bottom-width:0px;
}

.bg-black .dropdown-item:hover {
    background-color: black;
    color: white;
}